/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from './utils/redux-injectors';
import { PrivateRoute } from './utils/PrivateRoute';

// moment js
import * as moment from 'moment';
import 'moment/locale/uk';
import 'moment-duration-format';

// react toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyle } from 'styles/global-styles';

// main pages
import { HomePage } from './containers/HomePage/Loadable';
import { SignUpPage } from './containers/SignUpPage/Loadable';
import { NotFoundPage } from './components/layout/NotFoundPage/Loadable';

import { BroadcastingLibraryPage } from './containers/BroadcastingLibraryPage/Loadable';
import { BroadcastingSinglePage } from './containers/BroadcastingSinglePage/Loadable';

import { SpecialProjectArchivePage } from './containers/SpecialProjectArchivePage/Loadable';

// profile
import { ProfileLayout } from './containers/ProfileLayout/Loadable';

// auth pages
import { LoginPage } from './containers/LoginPage';
import { ResetPasswordByEmailPage } from './containers/ResetPassword/ResetPasswordByEmailPage';
import { ResetPasswordByPhonePage } from './containers/ResetPassword/ResetPasswordByPhonePage';
import { RestorePasswordPage } from './containers/RestorePasswordPage/Loadable';
import { ThanksForRegister } from './containers/ThanksForRegister/Loadable';
import { UserAlreadyConfirmed } from './containers/UserAlreadyConfirmed/Loadable';
import { UnauthorizedPage } from './containers/UnauthorizedPage/Loadable';
import { VerificationRestorePasswordBySmsPage } from './containers/VerificationBySmsCode/VerificationRestorePasswordBySmsPage/Loadable';

// layout
import { AppLayout } from './layouts/AppLayout';
import ModalLayout from './layouts/ModalLayout';

import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';

// locales
import { useTranslation } from 'react-i18next';

// store
import {
  sliceKey as appSliceKey,
  reducer as appReducer,
  actions,
} from './store/app/slice';

import { appSaga } from './store/app/saga';

import { useDispatch, useSelector } from 'react-redux';

// axios
import { $apiClient } from 'utils/request';

// hoc
import withErrorHandler from 'hoc/withErrorHandler';

// utils
import ScrollToTop from './utils/scrollToTop';
import { AuthPage } from './components/layout/AuthPage';
import { selectIsAuthenticated } from './store/app/selectors';
import { SavedPage } from './containers/SavedPage/Loadable';
import { UsefulLinksPage } from './containers/UsefulLinksPage/Loadable';
import { ClinicalCasePage } from './containers/ClinicalCasePage/Loadable';
import { ClinicalCaseSinglePage } from './containers/ClinicalCaseSinglePage/Loadable';
import { ProductsPage } from './containers/ProductsPage/Loadable';
import { ProductsSinglePage } from './containers/ProductsSinglePage/Loadable';
import { Monitoring } from './containers/Monitoring';

// import tag manager
import TagManager from 'react-gtm-module';
import { selectProfile } from './store/profile/selectors';

// toast modal configuration
toast.configure({
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  draggable: true,
  pauseOnHover: true,
});

export function App() {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    // gtmId: 'GTM-MZJH9Q3-1__Remove',
  };
  const isLoggedIn = useSelector(selectIsAuthenticated);
  let gtmHasInited = false;

  // inject reducer && saga
  useInjectReducer({ key: appSliceKey, reducer: appReducer });
  useInjectSaga({ key: appSliceKey, saga: appSaga });

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.checkAuthStatusTrigger());
  }, [dispatch]);

  const { i18n } = useTranslation();

  useEffect(() => {
    moment.locale('uk');
  }, []);

  const PageLayout = () => (
    <>
      <Helmet
        onChangeClientState={(newState, addedTags, removedTags) => {
          if (newState?.title !== 'Servier' && !gtmHasInited && !isLoggedIn) {
            TagManager.initialize(tagManagerArgs);
            gtmHasInited = true;
          }
        }}
        titleTemplate="%s - Простір Servier"
        defaultTitle="Servier"
        htmlAttributes={{ lang: 'ua' }}
      >
        <meta name="description" content="Servier" />
      </Helmet>

      <ScrollToTop />

      <Header />

      <AppSwitch />

      <Footer />

      <GlobalStyle />
    </>
  );

  console.log(process.env.NODE_ENV);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/cross_auth" component={AuthPage} />
        <Route path={'/monitoring'} component={Monitoring} />
        <Route component={PageLayout} />
      </Switch>
    </BrowserRouter>
  );
}

function AppSwitch() {
  const location = useLocation();

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname !== '/login' &&
      location.pathname !== '/thanks-for-register' &&
      location.pathname !== '/user-already-confirmed' &&
      location.pathname !== '/restore-password' &&
      location.pathname !== '/profile'
    )
      dispatch(actions.setLocation(location.pathname));
  }, []);

  // handle gtm register and login events START
  const profileInfo = useSelector(selectProfile);
  const isLoggedIn = useSelector(selectIsAuthenticated);

  function timeoutPromise(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    (async () => {
      await timeoutPromise(500);
      if (
        localStorage.getItem('register_event') == 'true' &&
        isLoggedIn &&
        profileInfo?.data?.id
      ) {
        const tagManagerArgs = {
          gtmId: process.env.REACT_APP_GTM_ID,
          dataLayer: {
            event: 'sign_up',
            uid: profileInfo?.data?.id, //User ID. Id зарегистрированного пользователя.
          },
        };
        TagManager.initialize(tagManagerArgs);
        await localStorage.removeItem('register_event');
        console.log('register_event');
      }
    })();

    (async () => {
      await timeoutPromise(500);
      if (
        localStorage.getItem('login_event') == 'true' &&
        isLoggedIn &&
        profileInfo?.data?.id
      ) {
        const tagManagerArgs = {
          gtmId: process.env.REACT_APP_GTM_ID,
          dataLayer: {
            event: 'login',
            uid: profileInfo?.data?.id, //User ID. Id зарегистрированного пользователя.
          },
        };
        TagManager.initialize(tagManagerArgs);
        await localStorage.removeItem('login_event');
        console.log('login_event');
      }
    })();
  }, [
    profileInfo?.data?.id,
    isLoggedIn,
    localStorage.getItem('login_event'),
    localStorage.getItem('register_event'),
  ]);
  // handle gtm register and login events END

  const [success, setSuccess] = useState(false);

  const closeModalWindow = value => {
    setSuccess(value);
  };

  // @ts-ignore
  const background = location.state && location.state.background;
  return (
    <AppLayout>
      <Switch location={background || location}>
        <Route exact path="/registration" component={SignUpPage} />

        <Route exact path={'/restore-password'} render={RestorePasswordPage} />

        <Route
          path={'/thanks-for-register'}
          render={() => (
            <ModalLayout>
              <ThanksForRegister />
            </ModalLayout>
          )}
        />
        <Route
          path={'/user-already-confirmed'}
          render={() => (
            <ModalLayout>
              <UserAlreadyConfirmed />
            </ModalLayout>
          )}
        />
        <Route
          path={'/unauthorised'}
          render={() => (
            <ModalLayout isUnauthorised>
              <UnauthorizedPage />
            </ModalLayout>
          )}
        />

        <Route exact path="/" component={HomePage} />

        <Route path="/profile" component={ProfileLayout} />

        <PrivateRoute path="/saved" component={SavedPage} />

        <Route exact path="/useful-links" component={UsefulLinksPage} />

        <Route exact path="/webinars" component={BroadcastingLibraryPage} />

        <PrivateRoute
          exact
          resourceType={'webinar'}
          path="/webinars/:slug"
          component={BroadcastingSinglePage}
        />

        <Route
          exact
          path="/specialprojects"
          component={SpecialProjectArchivePage}
        />

        <Route exact path="/clinical-cases" component={ClinicalCasePage} />

        <PrivateRoute
          exact
          path="/clinical-cases/:slug"
          component={ClinicalCaseSinglePage}
        />

        <Route exact path="/products" component={ProductsPage} />

        <PrivateRoute
          exact
          path="/products/:slug"
          component={ProductsSinglePage}
          singlePage={true}
        />

        <Route
          exact
          path="/specialprojects"
          component={SpecialProjectArchivePage}
        />

        <Route path="*" component={NotFoundPage} />

        <Redirect to="/" />
      </Switch>

      <Route
        exact
        path={'/login*'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <LoginPage activeTab={0} />
          </ModalLayout>
        )}
      />

      <Route
        exact
        path={'/registration'}
        render={() => (
          <ModalLayout isLoginAndRegistration closeModalPopup={success}>
            <LoginPage
              activeTab={1}
              closePopupMainComponent={closeModalWindow}
            />
          </ModalLayout>
        )}
      />

      <Route
        path={'/reset-password'}
        render={() => (
          <ModalLayout isResetPass>
            <ResetPasswordByEmailPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/reset-password-by-email'}
        render={() => (
          <ModalLayout isResetPass>
            <ResetPasswordByEmailPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/reset-password-by-phone'}
        render={() => (
          <ModalLayout>
            <ResetPasswordByPhonePage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/restore-password-by-sms-code'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <VerificationRestorePasswordBySmsPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/verification-mobile-phone'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <VerificationRestorePasswordBySmsPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/restore-password'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <RestorePasswordPage />
          </ModalLayout>
        )}
      />
    </AppLayout>
  );
}

export default withErrorHandler(App, $apiClient);
